<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Usuarios</h2>
    <div class="flex">
      <button
        class="
          rounded
          border border-primary
          bg-white
          py-2
          px-4
          text-primary
          font-medium
          flex
        "
        @click="onOpenDrawerUser({ action: 'create' })"
      >
        Agregar Usuario
        <span class="p-1 ml-1">
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1889 12.4936C12.8521 11.6957 12.3632 10.9709 11.7497 10.3596C11.1379 9.74661 10.4133 9.25785 9.61573 8.92034C9.60858 8.91677 9.60144 8.91499 9.5943 8.91141C10.7068 8.10784 11.43 6.79891 11.43 5.32213C11.43 2.8757 9.44787 0.893555 7.00144 0.893555C4.55501 0.893555 2.57287 2.8757 2.57287 5.32213C2.57287 6.79891 3.29608 8.10784 4.40858 8.9132C4.40144 8.91677 4.3943 8.91856 4.38715 8.92213C3.58715 9.25963 2.8693 9.74356 2.25323 10.3614C1.64021 10.9731 1.15145 11.6978 0.81394 12.4953C0.482372 13.2762 0.30355 14.1133 0.287154 14.9614C0.286677 14.9805 0.29002 14.9994 0.296986 15.0172C0.303951 15.0349 0.314399 15.0511 0.327712 15.0647C0.341025 15.0784 0.356935 15.0892 0.374504 15.0966C0.392074 15.104 0.410947 15.1079 0.430011 15.1078H1.50144C1.58001 15.1078 1.64251 15.0453 1.6443 14.9686C1.68001 13.59 2.23358 12.2989 3.21215 11.3203C4.22465 10.3078 5.5693 9.7507 7.00144 9.7507C8.43358 9.7507 9.77822 10.3078 10.7907 11.3203C11.7693 12.2989 12.3229 13.59 12.3586 14.9686C12.3604 15.0471 12.4229 15.1078 12.5014 15.1078H13.5729C13.5919 15.1079 13.6108 15.104 13.6284 15.0966C13.6459 15.0892 13.6619 15.0784 13.6752 15.0647C13.6885 15.0511 13.6989 15.0349 13.7059 15.0172C13.7129 14.9994 13.7162 14.9805 13.7157 14.9614C13.6979 14.1078 13.5211 13.2775 13.1889 12.4936ZM7.00144 8.39356C6.1818 8.39356 5.41037 8.07391 4.83001 7.49356C4.24965 6.9132 3.93001 6.14177 3.93001 5.32213C3.93001 4.50248 4.24965 3.73106 4.83001 3.1507C5.41037 2.57034 6.1818 2.2507 7.00144 2.2507C7.82108 2.2507 8.59251 2.57034 9.17287 3.1507C9.75322 3.73106 10.0729 4.50248 10.0729 5.32213C10.0729 6.14177 9.75322 6.9132 9.17287 7.49356C8.59251 8.07391 7.82108 8.39356 7.00144 8.39356Z"
              fill="#0057FF"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div class="lg:p-10 flex align-start p-5">
    <div class="flex relative">
      <input
        v-model.trim="search"
        class="
          relative
          p-1
          px-3
          lg:w-72
          bg-white
          rounded-sm
          border border-gray-400
          outline-none
          text-gray-700
          placeholder-gray-400
          pr-10
        "
        placeholder="Buscar usuario"
      />
      <span class="absolute inset-y-0 right-0 flex items-center pl-2">
        <button
          type="submit"
          class="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            class="w-8 h-8"
          >
            <path
              d="M23.1022 22.1188L18.4647 17.4813C19.1844 16.5509 19.5737 15.4134 19.5737 14.217C19.5737 12.7849 19.0147 11.442 18.004 10.4295C16.9933 9.41701 15.6469 8.85986 14.2165 8.85986C12.7862 8.85986 11.4397 9.41879 10.429 10.4295C9.41652 11.4402 8.85938 12.7849 8.85938 14.217C8.85938 15.6474 9.4183 16.9938 10.429 18.0045C11.4397 19.017 12.7844 19.5741 14.2165 19.5741C15.4129 19.5741 16.5487 19.1849 17.479 18.467L22.1165 23.1027C22.1301 23.1163 22.1463 23.1271 22.164 23.1345C22.1818 23.1418 22.2009 23.1456 22.2201 23.1456C22.2393 23.1456 22.2584 23.1418 22.2761 23.1345C22.2939 23.1271 22.3101 23.1163 22.3237 23.1027L23.1022 22.3259C23.1158 22.3123 23.1266 22.2962 23.134 22.2784C23.1414 22.2606 23.1452 22.2416 23.1452 22.2224C23.1452 22.2031 23.1414 22.1841 23.134 22.1663C23.1266 22.1485 23.1158 22.1324 23.1022 22.1188ZM17.0451 17.0456C16.2879 17.8009 15.2844 18.217 14.2165 18.217C13.1487 18.217 12.1451 17.8009 11.3879 17.0456C10.6326 16.2884 10.2165 15.2849 10.2165 14.217C10.2165 13.1491 10.6326 12.1438 11.3879 11.3884C12.1451 10.6331 13.1487 10.217 14.2165 10.217C15.2844 10.217 16.2897 10.6313 17.0451 11.3884C17.8004 12.1456 18.2165 13.1491 18.2165 14.217C18.2165 15.2849 17.8004 16.2902 17.0451 17.0456Z"
              fill="#0057FF"
            />
          </svg>
        </button>
      </span>
    </div>
  </div>
  <div class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead>
          <tr>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              #
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              DNI
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Nombre
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Rol
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Especialidad
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Categoría
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Correo
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Teléfono
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Fecha de creación
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-400">
          <template v-if="getMembers">
            <tr
              v-for="(member, i) in matchUsers"
              :key="i"
              class="whitespace-nowrap"
            >
              <td class="px-6 py-4 text-sm text-left">
                {{ i + 1 }}
              </td>
              <td class="px-6 py-4 text-sm text-left">
                {{ member.dni || "—" }}
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left uppercase">
                  {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left">
                  {{ member.currentRole?.name || "—" }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left">
                  {{ member.speciality?.name || "—" }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left">
                  {{ member.category?.name || "—" }}
                </div>
              </td>
              <td class="px-6 py-4 text-sm text-left">
                {{ member.email || "—" }}
              </td>
              <td class="px-6 py-4 text-sm text-left">
                {{ member.phone || "—" }}
              </td>
              <td class="px-6 py-4 text-sm text-left">
                {{ formatDate(member.createdAt) ?? "—" }}
              </td>
              <td class="px-6 py-4">
                <div class="flex md:justify-end">
                  <a
                    v-if="member.type !== 'Admin'"
                    class="
                      text-primary
                      font-bold
                      cursor-pointer
                      hover:bg-blue-50
                      px-2
                    "
                    @click="
                      onOpenDrawerUser({ action: 'update', user: member })
                    "
                  >
                    Editar
                  </a>
                  <button
                    class="ml-4 hover:bg-red-50 px-2"
                    @click="onDelete(member)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                        fill="#E00F57"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer :open="drawerUser" @update:open="drawerUser = $event">
    <UserForm
      v-if="drawerUser"
      :action="userFormAction"
      :user-selected="userSelected"
      @onComplete="drawerUser = false"
    />
  </EndDrawer>

  <Modal
    v-if="userSelected"
    :open="modalDeleteMember"
    @update:open="modalDeleteMember = $event"
  >
    <template #body>
      <span class="font-medium"
        >¿Desea eliminar al usuario {{ userSelected.lastName }}
        {{ userSelected.lastName2 }} {{ userSelected.name }} ({{
          userSelected.email
        }})?</span
      ><br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeleteMember()"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import UserForm from "./forms/User";

const store = useStore();

const search = ref("");
const drawerUser = ref(false);
const userFormAction = ref("create");
const userSelected = ref(null);
const matchUsers = ref([]);
const timerToSearch = ref(null);
const modalDeleteMember = ref(false);
const loadingDelete = ref(false);

const getMembers = computed(() => store.getters.getMembers);
const getSpecialities = computed(() => store.getters.getSpecialities);
const getCategories = computed(() => store.getters.getCategories);

watch(
  () => getMembers.value,
  (currentValue) => {
    if (!currentValue) return;

    matchUsers.value = [...currentValue];
  },
  { deep: true }
);

watch(
  () => search.value,
  () => searchUsers()
);

const loadUsers = async () => {
  await store.dispatch("getAllMembers", {
    userEntity: "User",
    type: ["Worker", "Admin"],
  });
  matchUsers.value = [
    ...getMembers.value.filter(
      (member) => member.email !== "matiascalmet93@gmail.com"
    ),
  ];
};

const init = async () => {
  await loadUsers();

  if (!getSpecialities.value)
    await store.dispatch("getAllSpecialities", { type: "User" });
  if (!getCategories.value) await store.dispatch("getAllCategories");
};

init();

const searchUsers = async () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    matchUsers.value = await store.dispatch("searchAllMembers", {
      qSearch: search.value,
      userEntity: "User",
    });
  }, 200);
};

const formatDate = (dateString) => {
  const year = parseInt(dayjs(dateString).format("YYYY"), 10);

  return !dateString || year < 1
    ? null
    : dayjs(dateString).format("DD/MM/YYYY");
};

const onOpenDrawerUser = ({ action, user = null }) => {
  userSelected.value = { ...user };
  drawerUser.value = true;
  userFormAction.value = action;
};

const onDelete = (user) => {
  userSelected.value = user;
  modalDeleteMember.value = true;
};

const onDeleteMember = async () => {
  try {
    loadingDelete.value = true;

    await store.dispatch("deleteMember", { key: userSelected.value.key });

    modalDeleteMember.value = false;
    userSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};
</script>
