<template>
  <Form @submit="onSave" :validation-schema="validationSchema" class="pb-4">
    <div class="">
      <h4 class="mb-6 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} usuario
      </h4>
    </div>
    <div class="mb-4">
      <label class="text-dark font-semibold">Correo electrónico</label>
      <Field v-model="email" name="email" v-slot="{ field }">
        <div class="relative w-full">
          <input
            v-bind="field"
            placeholder="Ingrese correo"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            @keyup="onSearchUser"
            autocomplete="off"
          />
          <template v-if="showSearchResult">
            <div
              class="
                absolute
                w-full
                z-50
                bg-white
                border border-gray-300
                mt-1
                max-height-48
                overflow-hidden overflow-y-hidden
                rounded-md
                shadow-md
              "
            >
              <ul v-if="matchUsers && matchUsers.length" class="bg-lightgray">
                <li
                  v-for="user in matchUsers"
                  :key="user.key"
                  class="
                    px-3
                    py-3
                    cursor-pointer
                    hover:bg-primary
                    text-primary
                    hover:text-white
                  "
                  @click="onSelectUser(user)"
                >
                  {{ user.email }}
                </li>
              </ul>
              <div
                v-if="matchUsers && matchUsers.length === 0 && email"
                class="w-full py-2 text-sm font-thin"
              >
                No se encontraron resultados
              </div>
            </div>
          </template>
        </div>
      </Field>
      <ErrorMessage name="email" v-slot="{ message }">
        <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
      </ErrorMessage>
    </div>
    <template
      v-if="
        !matchUserSelected ||
        (matchUserSelected && matchUserSelected.type !== 'Admin')
      "
    >
      <div class="mb-4">
        <div class="relative w-full flex flex-wrap items-stretch">
          <label class="text-dark font-semibold">Rol</label>
          <Field v-model="roleKey" name="roleKey" v-slot="{ field }">
            <select
              v-bind="field"
              class="
                relative
                py-3
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Seleccione"
            >
              <option
                v-for="role in getRoles"
                :key="role"
                :value="role.key"
                :selected="roleKey === role.key"
              >
                {{ role.name }}
              </option>
            </select>
          </Field>
        </div>
        <div>
          <ErrorMessage name="roleKey" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>
      <div class="mb-4">
        <div class="relative w-full flex flex-wrap items-stretch">
          <label class="text-dark font-semibold">Especialidad</label>
          <Field
            v-model="specialityKey"
            name="specialityKey"
            v-slot="{ field }"
          >
            <select
              v-bind="field"
              class="
                relative
                py-2
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Seleccione"
            >
              <option
                v-for="speciality in getSpecialities"
                :key="speciality"
                :value="speciality.key"
              >
                {{ speciality.name }}
              </option>
            </select>
          </Field>
        </div>
        <div>
          <ErrorMessage name="specialityKey" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>
      <div class="mb-4">
        <div class="relative w-full flex flex-wrap items-stretch">
          <label class="text-dark font-semibold">Categoría (opcional)</label>
          <Field v-model="categoryKey" name="categoryKey" v-slot="{ field }">
            <select
              v-bind="field"
              class="
                relative
                py-2
                px-2
                w-full
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
              placeholder="Seleccione"
            >
              <option
                v-for="category in getCategories"
                :key="category"
                :value="category.key"
              >
                {{ category.name }}
              </option>
            </select>
          </Field>
        </div>
        <div>
          <ErrorMessage name="categoryKey" v-slot="{ message }">
            <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
          </ErrorMessage>
        </div>
      </div>
    </template>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">DNI</label>
        <Field v-model="dni" name="dni" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese DNI"
            :disabled="isReadOnly"
            autocomplete="off"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="dni" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Nombre</label>
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese nombre"
            :disabled="isReadOnly"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Apellido paterno</label>
        <Field v-model="lastName" name="lastName" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese apellido paterno"
            :disabled="isReadOnly"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="lastName" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold"
          >Apellido materno (opcional)</label
        >
        <Field v-model="lastName2" name="lastName2" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese apellido materno"
            :disabled="isReadOnly"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="lastName2" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Empresa (opcional)</label>
        <Field v-model="company" name="company" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese empresa"
            :disabled="isReadOnly"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="company" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Teléfono (opcional)</label>
        <Field v-model="phone" name="phone" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-2
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Ingrese teléfono"
            :disabled="isReadOnly"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="phone" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-8
          text-white
          font-medium
          flex
          w-full
          justify-center
        "
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { useField } from "vee-validate";
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  userSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();

let { value: email } = useField("email");
const { value: roleKey } = useField("role");
const { value: specialityKey } = useField("specialityKey");
const { value: categoryKey } = useField("categoryKey");
const { value: dni } = useField("dni");
const { value: name } = useField("name");
const { value: lastName } = useField("lastName");
const { value: lastName2 } = useField("lastName2");
const { value: company } = useField("company");
const { value: phone } = useField("phone");

let timerToSearch = ref(null);
let showSearchResult = ref(false);
let matchUserSelected = ref(null);
let matchUsers = ref([]);
let isReadOnly = ref(false);
let responseType = ref(null);
let responseMessage = ref(null);
let loadingSave = ref(false);

const getRoles = computed(() => store.getters.getRoles);
const getSpecialities = computed(() => store.getters.getSpecialities);
const getCategories = computed(() => store.getters.getCategories);
const validationSchema = computed(() => {
  const rules = {
    dni: "required",
    name: "required",
    lastName: "required",
    email: "email",
  };

  if (
    !matchUserSelected.value ||
    (matchUserSelected.value && matchUserSelected.value.type !== "Admin")
  ) {
    rules.roleKey = "required";
    rules.specialityKey = "required";
  }

  return rules;
});

watch(
  () => email.value,
  (email, prevEmail) => {
    if (email !== prevEmail && showSearchResult.value) {
      matchUsers.value = [];
      showSearchResult.value = false;
      matchUserSelected.value = null;
    }
  }
);

const init = () => {
  if (props.action === "update") {
    roleKey.value = props.userSelected.currentRole?.key;
    specialityKey.value = props.userSelected.speciality?.key;
    categoryKey.value = props.userSelected.category?.key;
    dni.value = props.userSelected.dni;
    name.value = props.userSelected.name;
    lastName.value = props.userSelected.lastName;
    lastName2.value = props.userSelected.lastName2;
    email.value = props.userSelected.email;
    company.value = props.userSelected.company;
    phone.value = props.userSelected.phone;
  }
};

init();

const onSearchUser = () => {
  if (isReadOnly.value) {
    resetUserInputs();

    isReadOnly.value = false;
    matchUserSelected.value = null;
  }

  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    matchUsers.value = await store.dispatch("findUserByEmail", {
      email: email.value,
    });

    showSearchResult.value = false;
    if (matchUsers.value?.length) showSearchResult.value = true;
  }, 200);
};

const onSelectUser = (user) => {
  isReadOnly.value = true;
  matchUsers.value = [];
  showSearchResult.value = false;
  matchUserSelected.value = user;

  resetUserInputs(user);
};

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create") {
      if (matchUserSelected.value) {
        if (matchUserSelected.value.type === "Admin") {
          await store.dispatch("createMemberAdminExists", {
            member: {
              userKey: matchUserSelected.value.key,
              userEntity: "User",
            },
          });
        } else {
          await store.dispatch("createMemberExists", {
            member: {
              userKey: matchUserSelected.value.key,
              specialityKey: values.specialityKey,
              roleKey: values.roleKey,
              categoryKey: values.categoryKey,
              userEntity: "User",
            },
          });
        }
      } else
        await store.dispatch("createMember", {
          member: { ...values, password: "123456", userEntity: "User" },
        });
    } else {
      await store.dispatch("updateMember", {
        member: {
          ...values,
          key: props.userSelected.key,
        },
      });
    }

    matchUserSelected.value = null;
    resetForm();
    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};

const resetUserInputs = (user) => {
  name.value = user?.name;
  lastName.value = user?.lastName;
  lastName2.value = user?.lastName2;
  dni.value = user?.dni;
  company.value = user?.company;
  phone.value = user?.phone;
};
</script>
